import React, { useEffect, useState, useRef, lazy, Suspense } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BsFillCaretRightFill, BsFillCaretLeftFill } from 'react-icons/bs';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './stylecarrusel.css';
import evento1 from './images/carrusel/imagen1.jpg';
import evento2 from './images/carrusel/imagen2.jpg';
import evento3 from './images/carrusel/imagen3.png';
import evento4 from './images/carrusel/imagen4.png';
import evento5 from './images/carrusel/imagen5.jpg';
import potcast from './images/carrusel/potcast.png';
import evento6 from './images/carrusel/imagen6.png';
import evento7 from './images/carrusel/imagen7.png';
import evento8 from './images/carrusel/imagen8.jpg';
import evento9 from './images/carrusel/imagen9.jpg';
import evento10 from './images/carrusel/imagen10.jpg';
import { useNavigate } from 'react-router-dom';

const Carrusel = () => {
    const [isPrevActive, setIsPrevActive] = useState(false);
    const [isNextActive, setIsNextActive] = useState(false);
    const carouselRef = useRef(null);

    const arrowButtonStyle = {
        color: '#37a7c1',
        borderRadius: '25%',
        padding: '0.5rem',
        fontSize: '2rem',
        width: '2.5rem',
        height: '2.5rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        border: 'none',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        zIndex: 1,
        cursor: 'pointer',
    };

    const nextButtonStyle = {
        ...arrowButtonStyle,
        right: '-1rem',
    };

    const prevButtonStyle = {
        ...arrowButtonStyle,
        left: '-1rem',
    };

    const handleNextClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
            setIsNextActive(true);
            setTimeout(() => setIsNextActive(false), 200);
        }
    };

    const handlePrevClick = () => {
        if (carouselRef.current) {
            carouselRef.current.next();
            setIsPrevActive(true);
            setTimeout(() => setIsPrevActive(false), 200);
        }
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (carouselRef.current) {
                carouselRef.current.next();
                setIsNextActive(true);
                setTimeout(() => setIsNextActive(false), 500);
            }
        }, 10000);
        return () => clearInterval(intervalId);
    }, []);

    const divStyles = {
        boxShadow: '10px 5px 15px gray',
    };

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate('/numeros-de-emergencia');
    }


    return (
        <div className="relative w-auto flex items-center mx-10 mt-10 mb-20">
            <i className="fa fa-chevron-left" style={prevButtonStyle} onClick={handlePrevClick} />
            <i className="fa fa-chevron-right" style={nextButtonStyle} onClick={handleNextClick} />

            <Carousel 
                ref={carouselRef}
                interval={null}
                indicators={false}
                controls={false}
                className='w-full'
            >
                <Carousel.Item>
                    {/* <div className="w-full grid grid-cols-1 gap-2 mt-4 mb-4 items-center justify-center" style={{ padding: '0 2rem' }}> */}
                        
                        {/*FRASE */}
                        <div className=" flex items-center rounded-xl shadown-2xl h-full justify-center">
                            <article style={divStyles} className=' h-60 font-semibold aspect-square w-auto border-2 rounded-3xl flex flex-col justify-between items-center transition-transform duration-500 transform hover:scale-105 transform-style preserve-3d cursor-pointer group'>
                                <div className="relative  w-full h-full front rounded-3xl text-center bg-pink-100">
                                        <div className='absolute inset-0 flex flex-col items-center justify-center front-content '>
                                            <img className='w-auto h-full object-cove rounded-3xl' src={evento1} alt=""/>
                                        </div>
                                        
                                        <div className='absolute inset-0 flex flex-col items-center justify-center back-content rotate-y-180'>

                                            <h1 className="w-auto h-auto text-sm text-gray-600 font-light " >
                                                No te quedes callada, <br /> ¡No estas sola! 🌸🗣️📣
                                            </h1>
                                        </div>
                                </div>
                            </article>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>

                        {/*LIBROS/ARTICULOS RECOMENDADOS */}
                        <div className="flex items-center rounded-xl shadown-2xl h-full justify-center">
                            <a href="https://www.editdiazdesantos.com/wwwdat/pdf/9788479786281.pdf" target="_blank" rel="noopener noreferrer">
                                <article style={divStyles} className=' h-60 font-semibold aspect-square w-auto border-2 rounded-3xl flex flex-col justify-between items-center transition-transform duration-500 transform hover:scale-105 transform-style preserve-3d cursor-pointer group'>
                                    <div className="absolute w-full h-full front rounded-3xl text-center bg-orange-200">
                                        <div className='absolute inset-0 flex flex-col items-center justify-center front-content '>
                                            <img className='w-auto h-full object-cove rounded-3xl' src={evento8} alt="Termo"/>
                                        </div>
                                            
                                        <div className='absolute inset-0 flex flex-col items-center justify-center back-content rotate-y-180'>

                                            <p className="w-auto h-auto text-sm text-gray-600 font-light ">
                                                En este apartado te vamos a dejar recomendaciones semanales de libros o artículos que podrían interesarte. 
                                                <br/><br/> Da clic aquí para ver la recomendación de esta semana.
                                            </p>
                                        </div>
                                    </div>
                                </article>
                            </a>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>

                        {/*CENTROS DE AYUDA */}
                        <div className="flex items-center rounded-xl shadown-2xl h-full justify-center">
                            <article style={divStyles} className=' h-60 font-semibold aspect-square w-auto border-2 rounded-3xl flex flex-col justify-between items-center transition-transform duration-500 transform hover:scale-105 transform-style preserve-3d cursor-pointer group'>
                                <div className="absolute w-full h-full front rounded-3xl text-center bg-violet-200">
                                        <div className='absolute inset-0 flex flex-col items-center justify-center front-content '>
                                            <img className='w-auto h-full object-cove rounded-3xl' src={evento9} alt="Termo"/>
                                        </div>
                                        
                                        <div className='absolute inset-0 flex flex-col items-center justify-center back-content rotate-y-180'>

                                            <h1 className="w-auto h-auto text-sm text-gray-600 font-light ">
                                                Consulta aquí el directorio de los centros de justicia para las mujeres.
                                            </h1>
                                        </div>
                                    
                                </div>
                            </article>
                        </div>
                        </Carousel.Item>
                        <Carousel.Item>

                        {/*NUMEROS DE TELEFONO DE QUIEN PUEDE AYUDAR */}
                        <div className="flex items-center rounded-xl shadown-2xl h-full justify-center">
                            <article 
                                    onClick={handleRedirect}
                                    style={divStyles} 
                                    className=' h-60 font-semibold aspect-square w-auto border-2 rounded-3xl flex flex-col justify-between items-center transition-transform duration-500 transform hover:scale-105 transform-style preserve-3d cursor-pointer group'
                            >
                                <div className="absolute w-full h-full front rounded-3xl text-center bg-red-200">
                                        <div className='absolute inset-0 flex flex-col items-center justify-center front-content '>
                                            <img className='w-auto h-full object-cove rounded-3xl' src={evento10} alt="Termo"/>
                                        </div>
                                        
                                        <div className='absolute inset-0 flex flex-col items-center justify-center back-content rotate-y-180'>

                                            {/* <h1 className="text-black text-center text-lg font-bold mt-2 mr-4 ml-4 mb-2 line-clamp-6" style={{fontFamily: 'Poppins, sans-serif' }}>
                                                Números de EMERGENCIA a los que puedes llamar
                                            </h1> */}

                                            <ul className=" text-left pl-5 mt-4 text-sm text-gray-600">
                                                <li className='text-gray-600'>911 - Emergencias Generales</li>
                                                <li>800 800 8000 - Apoyo Psicológico</li>
                                                <li>800 123 1234 - Línea de Violencia</li>
                                                <li>555 555 5555 - Centro de Apoyo</li>
                                            </ul>

                                            {/* <h1 className="text-black text-center text-lg font-normal mt-2 mr-4 ml-4 mb-2 line-clamp-6" style={{fontFamily: 'Poppins, sans-serif' }}>
                                                Da clic AQUÍ si necesitas algún otro número de teléfono.
                                            </h1> */}
                                        </div>
                                    
                                </div>
                            </article>
                        </div>
                    {/* </div> */}
                </Carousel.Item>

                <Carousel.Item>
                    {/* <div className="w-full grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-2 mt-4 mb-4 items-center justify-center" style={{ padding: '0 2rem' }}> */}
                        
                        {/*DONDE DENUNCIAR */}
                        <div className=" flex items-center rounded-xl shadown-2xl h-full justify-center">
                            <article style={divStyles} className=' h-60 font-semibold aspect-square w-auto border-2 rounded-3xl flex flex-col justify-between items-center transition-transform duration-500 transform hover:scale-105 transform-style preserve-3d cursor-pointer group'>
                                <div className="relative  w-full h-full front rounded-3xl text-center bg-violet-200">
                                        <div className='absolute inset-0 flex flex-col items-center justify-center front-content '>
                                            <img className='w-auto h-full object-cove rounded-3xl' src={evento5} alt="Termo"/>
                                        </div>
                                        
                                        <div className='absolute inset-0 flex flex-col items-center justify-center back-content rotate-y-180'>
                                            <h1 className="w-auto h-auto text-sm text-gray-600 font-light ">
                                                ¿Donde denunciar?
                                            </h1>
                                        </div>
                                </div>
                            </article>
                        </div>
                        </Carousel.Item>

                        <Carousel.Item>

                        {/*POTCAST */}
                        <div className="flex items-center rounded-xl shadown-2xl h-full justify-center">
                            <a href="https://open.spotify.com/show/7BgmoCm7maMCX0wUeN7p8l?si=hi1zt2OkQCq9NHaaR0A0Gg&nd=1&dlsi=a86e6966077748ac" target="_blank" rel="noopener noreferrer">
                                <article style={divStyles} className='mr-0 h-60 font-semibold aspect-square w-auto border-2 rounded-3xl flex flex-col justify-between items-center transition-transform duration-500 transform hover:scale-105 transform-style preserve-3d cursor-pointer group'>
                                    <div className="absolute w-full h-full front rounded-3xl text-center bg-lime-100">
                                        
                                            <div className='absolute inset-0 flex flex-col items-center justify-center front-content '>
                                                <img className='w-auto h-full object-cove rounded-3xl' src={potcast} alt="Termo"/>
                                            </div>
                                            
                                            <div className='absolute inset-0 flex flex-col items-center justify-center back-content rotate-y-180'>
                                                <h2 className="w-auto h-auto text-sm text-gray-600 font-light ">
                                                    Da clic aquí para escuchar el potcast "Igualdad y lucha contra la violencia de genero"
                                                </h2>
                                            </div>
                                        
                                    </div>
                                </article>
                            </a>
                            
                        </div>
                        </Carousel.Item>

                        <Carousel.Item>

                        {/*DIA DE LA VIOLENCIA */}
                        <div className=" flex items-center rounded-xl shadown-2xl h-full justify-center">
                            <a href="https://www.cndh.org.mx/noticia/dia-internacional-de-la-eliminacion-de-la-violencia-contra-la-mujer-1" target="_blank" rel="noopener noreferrer">
                                <article style={divStyles} className=' h-60 font-semibold aspect-square w-auto border-2 rounded-3xl flex flex-col justify-between items-center transition-transform duration-500 transform hover:scale-105 transform-style preserve-3d cursor-pointer group'>
                                    <div className="relative  w-full h-full front rounded-3xl text-center bg-violet-200">
                                            <div className='absolute inset-0 flex flex-col items-center justify-center front-content '>
                                                <img className='w-auto h-full object-cove rounded-3xl' src={evento6} alt="Termo"/>
                                            </div>
                                            
                                            <div className='absolute inset-0 flex flex-col items-center justify-center back-content rotate-y-180'>

                                                <p className="w-auto h-auto text-sm text-gray-600 font-light ">
                                                    Este día ha sido elegido como un acto de honra de la memoria de las hermanas Patria, Minerva y María Teresa Mirabal, tres activistas políticas 
                                                    de la República Dominicana.. <br/>Lee más aquí...
                                                </p>
                                            </div>
                                    </div>
                                </article>
                            </a>
                            
                        </div>
                        </Carousel.Item>

                        <Carousel.Item>

                        {/*NOTICIAS SOBRE LA VIOLENCIA DE GENERO */}
                        <div className="flex items-center rounded-xl shadown-2xl h-full justify-center">
                            <a href="https://www.elfinanciero.com.mx/estados/2024/11/11/caso-melanie-barragan-cual-es-su-estado-de-salud-y-que-cirugias-necesita-tras-la-agresion-de-christian-r/" target="_blank" rel="noopener noreferrer">
                                <article style={divStyles} className=' h-60 font-semibold aspect-square w-auto border-2 rounded-3xl flex flex-col justify-between items-center transition-transform duration-500 transform hover:scale-105 transform-style preserve-3d cursor-pointer group'>
                                    <div className="absolute w-full h-full front rounded-3xl text-center bg-pink-200">
                                        
                                            <div className='absolute inset-0 flex flex-col items-center justify-center front-content '>
                                                <img className='w-auto h-full object-cove rounded-3xl' src={evento7} alt="Termo"/>
                                            </div>
                                            
                                            <div className='absolute inset-0 flex flex-col items-center justify-center back-content rotate-y-180'>

                                                <h2 className="w-auto h-auto text-sm text-gray-500 font-light ">
                                                    Da clic aquí para ver la noticia más reciente de violencia contra la mujer.
                                                </h2>
                                            </div>
                                        
                                    </div>
                                </article>
                            </a>
                            
                        </div>
                    {/* </div> */}
                </Carousel.Item>
            </Carousel>

        </div>
        
      );
};

export default Carrusel;