import React from 'react';
import niunamas from './images/niunamas.png';


const SoporteLegal = ({ title, content }) => {
  return (
    <div className="text-center font-sans py-10" style={{ backgroundColor: '#f2e9e4' }}>
        <div className='text-gray-700'>
          <h2 id="conoceme" className="text-3xl font-bold mb-4" style={{ color: '#9775bb' }}>¿Quiénes Somos?</h2>
            <p>
                Somos una organización comprometida con brindar apoyo a mujeres, niñas y adolescentes víctimas de violencia. Nuestra misión es educar, prevenir y acompañar en el proceso de recuperación.
            </p>
            <div className="mt-6">
                <h3 className="text-3xl font-semibold" style={{ color: '#9775bb' }}>Aliados y patrocinadores</h3>
                <p>Empresas y organizaciones que nos apoyan en nuestra misión.</p>
            </div>
            <div className='text-sm text-black font-bold flex text-center justify-center gap-10'>
            <h3 className='' style={{ color: '#9775bb' }}>Diana Serrano <br /> Licenciada en Psicología</h3>
            <h3 className='' style={{ color: '#9775bb' }}>Gabriela Sarabia <br /> Licenciada en Derecho</h3>
            </div>
            
          </div>
    </div>
  );
};

export default SoporteLegal;
