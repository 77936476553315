import React from 'react';
import caminemosjuntas from './images/caminemosjuntas.jpeg';


const Banner = () => {

  return (
    <div className='font-sans'>
        <div className="relative text-center group overflow-hidden">
            
            <div className='fixed top-0 right-0 left-0 pr-4 flex justify-end py-2 text-black bg-opacity-50 bg-black z-10 font-sans'>
            <button href="#conoceme" className="hover:bg-cyan-400 hover:text-black rounded-lg px-2 py-2 text-sm text-white">¿Quienes somos?</button>
            <button href="#quehacemos" className="hover:bg-cyan-400 hover:text-gray-900 rounded-lg px-2 py-2 text-sm text-white">¿Que hacemos?</button>
            <button href="#contacto" className="hover:bg-cyan-400 hover:text-gray-900 rounded-lg px-2 py-2 text-sm text-white">Contacto</button>
            <button href="#apoyo-legal" className="hover:bg-cyan-400 hover:text-gray-900 rounded-lg px-2 py-2 text-sm text-white">Apoyo Psicologico/Legal</button>
            </div>
            
            <img className='w-full h-[600px] object-cover object-top rounded-b-3xl blur' src={caminemosjuntas} alt="Poder Femenino" />
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white group-hover: rounded-b-3xl z-10" style={{fontFamily: 'Poppins, sans-serif' }}>
            <h1 className="text-5xl font-extrabold font-sans">¡Caminemos Juntas!</h1>
            <p className="text-lg font-sans">No estás sola, estamos aquí para ayudarte</p>
            
            </div>
        </div>
    <div className='bg-gradient-to-b from-purple-200 py-10'></div>
    </div>

  );
};

export default Banner;
