const faqs = [
    {
        question: ["hola", "hola cami", "ayuda", "neecesito ayuda"],
        responses: [
            { 
                text: "¡Hola! ¿En qué puedo ayudarte?", 
                buttons: ["necesito ayuda", "información general"] 
            }
        ]
    },
    {
        question: ["necesito ayuda"],
        responses: [
            { 
                text: "Estoy aquí para ti. ¿Quieres que empecemos con apoyo emocional o legal?", 
                buttons: ["apoyo emocional", "apoyo legal"] 
            }
        ]
    },
    {
        question: ["apoyo emocional"],
        responses: [
            { 
                text: "¿Te gustaria platicar con una psicologa?", 
                buttons: ["Sí quiero hablar con una psicologa", "No, prefiero contactos de emergencia"] 
            }
        ]
    },
    {
        question: ["Sí quiero hablar con una psicologa", "psicologa", "necesito hablar con una psicologa", "psicologica", "psicologo", "ayuda psicologica", "necesito ayuda psicologica"],
        responses: [
            { 
                text: "Psicologa Diana Serrano Osorio: \n Teléfono 2221133330 \n- ¿Necesitas más información?", 
                buttons: ["Si, volver al inicio", "No, gracias"] 
            }
        ]
    },
    {
        question: ["No, prefiero contactos de emergencia", "contactos de emergencia"],
        responses: [
            { 
                text: "911 - Emergencias Generales \n- 800 800 8000 - Apoyo Psicológico \n- 800 123 1234 - Línea de Violencia \n- 555 555 5555 - Centro de Apoyo \n- ¿Hay algo más en lo que pueda ayudarte?", 
                buttons: ["Si, volver al inicio", "No, gracias"] 
            }
        ]
    },
    {
        question: ["Si, volver al inicio", "No, gracias"],
        responses: [
            { 
                text: "¡Hola! ¿En qué puedo ayudarte?", 
                buttons: ["necesito ayuda", "información general"] 
            }
        ]
    },
    {
        question: ["información general"],
        responses: [
            { 
                text: "Puedo ayudarte con información sobre violencia, recursos legales, grupos de apoyo y más. ¿Qué te gustaría saber?", 
                buttons: ["violencia", "recursos legales"] 
            }
        ]
    },
    {
        question: ["violencia"],
        responses: [
            { 
                text: "La violencia puede manifestarse de muchas formas. ¿Te gustaría saber más sobre violencia física, psicológica, económica o sexual?", 
                buttons: ["violencia física", "violencia psicológica", "violencia económica", "violencia sexual"] 
            }
        ]
    },
    {
        question: ["violencia física"],
        responses: [
            { 
                text: "La violencia física incluye golpes, empujones, mordeduras u otras acciones que causen daño físico. Si estás en peligro, llama al 911. ¿Necesitas más información?", 
                buttons: ["Si, volver al inicio", "No, gracias"] 
            }
        ]
    },
    {
        question: ["violencia psicológica"],
        responses: [
            { 
                text: "La violencia psicológica incluye insultos, amenazas, manipulación o cualquier acción que afecte tu autoestima y bienestar emocional. ¿Te gustaría recibir apoyo psicológico?", 
                buttons: ["Si, volver al inicio", "No, gracias"] 
            }
        ]
    },
    {
        question: ["violencia económica"],
        responses: [
            { 
                text: "La violencia económica ocurre cuando alguien controla o restringe tus recursos financieros, como evitar que trabajes o controlar tu dinero. ¿Te gustaría conocer recursos legales para enfrentar esto?", 
                buttons: ["Si, volver al inicio", "No, gracias"] 
            }
        ]
    },
    {
        question: ["violencia sexual"],
        responses: [
            { 
                text: "La violencia sexual incluye cualquier acto no consensuado que atente contra tu libertad sexual. Si necesitas ayuda inmediata, llama al 911. ¿Te gustaría recibir apoyo psicológico o legal?", 
                buttons: ["apoyo psicológico", "apoyo legal"] 
            }
        ]
    },
    {
        question: ["recursos legales"],
        responses: [
            { 
                text: "Puedo ayudarte a encontrar recursos legales. ¿Te gustaría más información sobre dónde acudir?", 
                buttons: ["Sí, quiero apoyo legal", "No"] 
            }
        ]
    },
    {
        question: ["Sí, quiero apoyo legal", "Sí, quiero apoyo legal"], // Respuesta específica para "recursos legales"
        responses: [
            { 
                text: "Aquí tienes algunos recursos legales disponibles: \n- Abogada Gabriela Sarabia: Teléfono 9531776747 \n- Línea Nacional de Asesoría Legal: 800 123 4567. ¿Necesitas más información?", 
                buttons: ["Si, volver al inicio", "No, gracias"] 
            }
        ]
    },
    {
        question: ["me siento sola", "me siento triste", "sufro de depresión"],
        responses: [
            { 
                text: "Lamento que te sientas así. Es importante buscar apoyo emocional. ¿Te gustaría hablar con una psicóloga?", 
                buttons: ["Sí", "No"] 
            }
        ]
    },
    {
        question: ["mi esposo me golpea", "mi novio me violenta"],
        responses: [
            { 
                text: "Lamento mucho que estés pasando por esto. Es importante que busques ayuda inmediata. ¿Te gustaría hablar con una abogada o una psicóloga?", 
                buttons: ["hablar con una abogada", "hablar con una psicóloga"] 
            }
        ]
    },
    {
        question: ["necesito una asesoría", "me gustaría hablar con una abogada"],
        responses: [
            { 
                text: "Puedo ponerte en contacto con una abogada. ¿Te gustaría que te comparta la información de contacto?", 
                buttons: ["Sí", "No"] 
            }
        ]
    },
    {
        question: ["Sí", "sí"], // Respuesta específica para "hablar con una abogada"
        responses: [
            { 
                text: "Aquí tienes la información de contacto de la abogada Gabriela Sarabia: \n- Teléfono: 9531776747 \n-  ¿Necesitas más información?", 
                buttons: ["Sí", "No"] 
            }
        ]
    },
    {
        question: ["me gustaría hablar con una psicóloga"],
        responses: [
            { 
                text: "Puedo ponerte en contacto con una psicóloga. ¿Te gustaría que te comparta la información de contacto?", 
                buttons: ["Sí", "No"] 
            }
        ]
    },
    {
        question: ["Sí", "sí"], // Respuesta específica para "hablar con una psicóloga"
        responses: [
            { 
                text: "Aquí tienes la información de contacto de la psicóloga Diana Serrano: \n- Teléfono: 2221133330 \n- Correo: diana.serrano@example.com. ¿Necesitas más información?", 
                buttons: ["Sí", "No"] 
            }
        ]
    },
    {
        question: ["necesito ayuda urgente", "números de ayuda en mi estado"],
        responses: [
            { 
                text: "Si necesitas ayuda urgente, aquí tienes algunos números de contacto: \n- Línea Nacional de Ayuda: 800 822 4460 \n- Abogada Gabriela Sarabia: 9531776747 \n- Psicóloga Diana Serrano: 2221133330. ¿Necesitas más información?", 
                buttons: ["Sí", "No"] 
            }
        ]
    },
    {
        question: ["default"],
        responses: [
            { 
                text: "Lo siento, no entendí tu mensaje. ¿Puedes reformularlo o preguntar algo diferente?", 
                buttons: [] 
            }
        ]
    }
];

export default faqs;