import React, { useState } from "react";
import SoportePsicologico from './SoportePsicologico';
import Carrusel from './Carrusel';

const QuienesSomos = () => {

    const [openModal, setOpenModal] = useState(null);
  
    const handleOpenModal = (modal) => setOpenModal(modal);
    const handleCloseModal = () => setOpenModal(null);

  return (
    <div >
      <p>
        
        <section id="quehacemos" className='w-auto min-h-auto grid lg:grid-cols-2 grid-cols-1 [&>*:last-chils]:col-span-9 font-sans py-10' style={{ backgroundColor: '#ebe0f0' }}>
          <aside className="flex flex-col  space-y-4 px-8">
          <b className='text-3xl font-sans mt-4 text-gray-500 font-bold' style={{ color: '#9775bb' }}>Encuentra ayuda y apoyo en los que puedes confiar</b>
          <div className="flex items-center"> 
              <p className={'text-md font-thin text-gray-900 text-justify flex items-center justify-center'}>
                Si estás en peligro inmediato, por favor llama al 911. Aquí encontrarás recursos y apoyo para ayudarte a salir de 
                situaciones de violencia. No estás sola, estamos aquí para ti.
              </p>
              
            </div>
            <p className={'text-md font-thin text-gray-900 text-justify flex items-center justify-center'}>
              ¿Te sientes insegura? ¿Has sufrido acoso, violación, abuso sexual, violencia física o psicológica?
              </p>
              <div className=" font-sans flex">
                  <button className="bg-[#37a7c1] hover:bg-[#c4c9ff] text-white py-2 px-3 rounded-lg m-2"                 
                  onClick={() => handleOpenModal("solicitarAyuda")}
                  >
                    Solicitar ayuda
                  </button>
                      <button
                        className="bg-[#37a7c1] hover:bg-[#c4c9ff] text-white py-2 px-3 rounded-lg m-2"
                        onClick={() => handleOpenModal("contactoEmergencia")}
                    >
                        Contacto de emergencia
                    </button>
                    <button
                        className="bg-[#37a7c1] hover:bg-[#c4c9ff] text-white py-2 px-3 rounded-lg m-2"
                        onClick={() => handleOpenModal("verServicios")}
                    >
                        Ver servicios
                    </button>
                    {openModal === "solicitarAyuda" && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full">
                                <h2 className="text-xl font-bold mb-2">Solicitar Ayuda</h2>
                                <p className="mb-4 text-gray-500">
                                    <strong>Abogada Gabriela Sarabia</strong>
                                    <br />
                                    Teléfono: 953-177-6747
                                </p>
                                <p className="mb-4 text-gray-500">
                                    <strong>Psicóloga Diana Serrano</strong>
                                    <br />
                                    Teléfono: 222-113-3330
                                </p>
                                <button
                                    className="py-2 px-4  bg-black rounded-lg hover:bg-gray-300"
                                    onClick={handleCloseModal}
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    )}

                    {openModal === "contactoEmergencia" && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full">
                                <h2 className="text-xl font-bold mb-2">Contacto de Emergencia</h2>
                                <ul className="list-disc list-inside mb-4 text-gray-500">
                                    <li>911 - Emergencias Generales</li>
                                    <li>800 800 8000 - Apoyo Psicológico</li>
                                    <li>800 123 1234 - Línea de Violencia</li>
                                    <li>555 555 5555 - Centro de Apoyo</li>
                                </ul>
                                <button
                                    className="py-2 px-4  bg-black rounded-lg hover:bg-gray-300"
                                    onClick={handleCloseModal}
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    )}

                    {openModal === "verServicios" && (
                        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                            <div className="bg-white rounded-lg p-4 shadow-lg max-w-sm w-full">
                                <h2 className="text-xl font-bold mb-2">Servicios Disponibles</h2>
                                <ul className="list-disc list-inside mb-4 text-gray-500">
                                    <li>Asistente virtual 24/7</li>
                                    <li>Chat en vivo con profesionales</li>
                                    <li>Asesoría legal</li>
                                    <li>Asesoría psicológica</li>
                                </ul>
                                <button
                                    className="py-2 px-4  bg-black rounded-lg hover:bg-gray-300"
                                    onClick={handleCloseModal}
                                >
                                    Cerrar
                                </button>
                            </div>
                        </div>
                    )}
                
                    
              </div>
      
              
          </aside>

          <aside>
              <Carrusel/>
          </aside>
        </section>
      </p>
    </div>
  );
};

export default QuienesSomos;
